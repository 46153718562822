.sdg-icon {
    max-width: 100%;
    max-height: 100%;
}

.sdg-icon-mobile {
  height: 55px;
  width: 55px;
}

.link-item {
  align-items: center;
  text-align: left;
  padding: 5px;
  font-size: 1em;
}

@media only screen and (max-width: 890px) {
  .link-item {
    font-size: 0.8em;
  }
}

.disabled-link-item {
  color: lightgray;
  cursor: default;
}

hr.solid {
  border-top: 1px solid;
  width: 93%;
}

div.tooltip {
  position: absolute;
  padding: 4px;
  background: #fff;
  border: 1px solid #999;
  border-radius: 4px;
  pointer-events: none;
  font-size: 1em;
  width: 20vw;
}

.network-canvas {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.network-svg {
  position: absolute;
  top: 0px;
  left: 0px;
}

.hidescrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.hidescrollbar::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
