.tutorial-button-container {
  position: fixed;
  bottom: 20px;
  right: 0px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  background-color: #FD6925;
}
